import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { ConnectKitProvider, getDefaultConfig } from "connectkit";
import React, { useEffect } from "react";
import { Provider } from "react-redux";
import { useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { WagmiConfig, createConfig } from "wagmi";
import {
  boba,
  bsc,
  bscTestnet,
  mainnet,
  polygon,
  polygonMumbai,
  polygonZkEvm,
  polygonZkEvmTestnet,
  sepolia,
} from "wagmi/chains";
import store from "../src/redux/store";
import Routing from "./routes";
import TagManager from "react-gtm-module";

// Components
import ErrorBoundary from "./components/ErrorBoundary/ErrorBoundary";
import SomeThingWentWrong from "./pages/error/SomeThingWentWrong";

const bobaTestnet = {
  id: 2888,
  name: "BobaTestnet",
  network: "boba-testnet",
  nativeCurrency: {
    name: "Boba Testnet Ether",
    symbol: "BOBA",
    decimals: 18,
  },
  rpcUrls: {
    default: {
      http: ["https://goerli.boba.network"],
    },
    public: {
      http: ["https://goerli.boba.network"],
    },
  },
  blockExplorers: {
    default: {
      url: "https://testnet.bobascan.com",
    },
  },
  testnet: true,
};

const chains =
  process.env.REACT_APP_ENVIRONMENT === "production"
    ? [mainnet, boba, bsc, polygon, polygonZkEvm]
    : [
        sepolia,
        polygonMumbai,
        bobaTestnet,
        bscTestnet,
        polygonMumbai,
        polygonZkEvmTestnet,
      ];

// Mainnet Config
// import { mainnet, boba, bsc, polygon, polygonZkEvm } from 'wagmi/chains';
// const chains = [mainnet, boba, bsc, polygon, polygonZkEvm];

console.log(
  "ENVIRONMENT",
  process.env.REACT_APP_ENVIRONMENT,
  process.env.REACT_APP_ALCHEMY_ID
);
const config = createConfig(
  getDefaultConfig({
    // Required API Keys
    alchemyId: process.env.REACT_APP_ALCHEMY_ID, // or infuraId
    walletConnectProjectId: process.env.REACT_APP_WALLETCONNECT_PROJECT_ID,
    appName: "Epiko Market",
    appDescription: "NFT marketplace",
    appUrl: "https://epiko.market", // your app's url
    appIcon: "../assets/images/logo.svg", // your app's icon, no bigger than 1024x1024px (max. 1MB)
    autoConnect: true,
    chains,
  })
);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

function App() {
  const location = useLocation();

  useEffect(() => {
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
  }, [location.pathname]);

  useEffect(() => {
    TagManager.initialize({ gtmId: "GTM-PBBX8RTV" });
  }, []);

  return (
    <>
      <QueryClientProvider client={queryClient}>
        <WagmiConfig config={config}>
          <ConnectKitProvider
            options={{ walletConnectName: "WalletConnect" }}
            mode="light"
            customTheme={{
              "--ck-font-family": "Outfit",
              "--ck-modal-heading-font-weight": 900,
              // '--ck-font-size': '30px',
            }}
          >
            <Provider store={store}>
              <ErrorBoundary fallback={<SomeThingWentWrong />}>
                <Routing />
              </ErrorBoundary>
            </Provider>
          </ConnectKitProvider>
        </WagmiConfig>
        {/* <ReactQueryDevtools initialIsOpen={false} /> */}
      </QueryClientProvider>
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover={false}
        theme={"light"}
      />
    </>
  );
}

export default App;
