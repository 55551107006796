import { useQuery } from "@tanstack/react-query";
import React from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { getHomePage } from "../../api/adapters/home-page";
import CardMedium from "../../components/CardMedium/CardMedium";
import { toggleLoader } from "../../redux/actions/loader";

// COMPONENTS
import CardLarge from "../../components/CardLarge/CardLarge";
import CreateSellNfts from "../../components/CreateSellNfts/CreateSellNfts";
import HomeBanner from "../../components/HomeBanner/HomeBanner";

// IMAGES
import Arrow2 from "../../assets/images/arrow-2.svg";
import EcosystemBg from "../../assets/images/ecosystem-bg.png";
import EcosystemAnimation from "../../assets/images/ecosystem-img-animation.svg";
import EcosystemImg from "../../assets/images/ecosystem-img.png";
import TopCreatorCollector from "../../components/TopCreatorCollector/TopCreatorCollector";
import { settings } from "../../configs/slider.config";
import Blogs from "../../components/BlogComponents/Blogs";

function Home() {
  const dispatch = useDispatch();

  const { data: homePage } = useQuery({
    queryKey: ["homePage"],
    queryFn: () =>
      getHomePage().then((res) => {
        setTimeout(() => {
          dispatch(toggleLoader(false));
        }, 300);
        return res.data.data;
      }),
  });

  return (
    <>
      <HomeBanner
        images={homePage?.aSliderNFTs}
        nTotalArtWorks={homePage?.nTotalArtWorks}
        totalAuctionNft={
          homePage?.nTotalNFTsOnAuction?.[0]?.nTotalNFTsOnAuction?.[0]
            ?.nTotalNFTsOnAuction
        }
        totalArtist={
          homePage?.nTotalNFTsOnAuction?.[0]?.nTotalCreators?.[0]
            ?.nTotalCreators
        }
      />
      {/* Brands */}
      <section className="bg-darkGray">
        <div className="container section-space">
          <div className="flex items-center justify-between mb-8">
            <h2 className="main-heading-h2">Brands</h2>
            <Link to="/all-brands" className="group btn-border btn-sm">
              View All
              <img
                src={Arrow2}
                alt="Arrow2"
                className="ml-1 duration-300 invert group-hover:invert-0"
              />
            </Link>
          </div>
          {homePage && (
            <Slider {...settings}>
              {homePage?.aBrands &&
                homePage.aBrands.map((item, i) => {
                  return (
                    item.aNfts.length && (
                      <div key={i} className="p-2.5">
                        <CardLarge
                          data={item}
                          redirect={`/user/${item.sBrandName}`}
                        />
                      </div>
                    )
                  );
                })}
            </Slider>
          )}
        </div>
      </section>
      {/* NFTs */}
      <section className="">
        <div className="container section-space">
          <div className="flex items-center justify-between mb-8">
            <h2 className="main-heading-h2">NFTs</h2>
            <Link to="/all-nfts" className="group btn-border btn-sm">
              View All
              <img
                src={Arrow2}
                alt="Arrow2"
                className="ml-1 duration-300 invert group-hover:invert-0"
              />
            </Link>
          </div>
          {homePage && (
            <Slider {...settings}>
              {homePage?.aNfts &&
                homePage.aNfts.map((item, i) => {
                  return (
                    <div key={item._id} className="p-2.5 h-full">
                      <CardMedium
                        data={item}
                        chainId={item.nChainId}
                        isSlider={true}
                      />
                    </div>
                  );
                })}
            </Slider>
          )}
        </div>
      </section>
      {/* Top Creators and Collectors */}
      <TopCreatorCollector />
      {/* WhitePaper */}
      <section>
        <div className="container py-20 md:py-18 sm:py-14">
          <div
            className="max-w-[1528px] mx-auto flex gap-10 bg-[image:var(--image-url)] bg-[length:100%_100%] items-center py-6 px-16 lg:px-12 md:px-10 md:bg-repeat md:bg-auto sm:flex-col sm:px-4 sm:py-4"
            style={{ "--image-url": `url(${EcosystemBg})` }}
          >
            <div className="w-1/2 sm:w-full">
              <h2 className="main-heading-h2">Epiko Ecosystem</h2>
              <p className="text-base text-black mt-4 lg:text-sm">
                Welcome to Epiko, a captivating ecosystem of decentralized Web3
                games inspired by ancient legends and cultural heritage. Set in
                a vibrant world of strategic challenges and mythical heroes,
                Epiko offers an immersive gaming experience enhanced with
                blockchain technology. Explore diverse game modes, collect and
                trade NFTs, and engage in epic battles. Epiko’s decentralized
                protocol empowers players and token holders with governance
                rights, enabling them to influence the game's development and
                tokenomics while earning rewards and sharing in the ecosystem's
                growth.
              </p>
              <Link
                to="https://docs.epiko.io/"
                className="group btn-secondary mt-7"
                target="_blank"
              >
                WHITEPAPER
                <img
                  src={Arrow2}
                  alt="Arrow2"
                  className="ml-3 group-hover:invert"
                />
              </Link>
            </div>
            <div className="w-1/2 text-center sm:w-full">
              <div className="relative inline-block">
                <img
                  src={EcosystemImg}
                  alt="EcosystemImg"
                  className="p-5 lg:p-4 xs:p-3"
                />
                <img
                  src={EcosystemAnimation}
                  alt="EcosystemAnimation"
                  className="animate-spin-slow absolute top-0 right-0 w-full h-full"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <CreateSellNfts />
      <Blogs />
    </>
  );
}

export default Home;
